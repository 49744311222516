import { useEffect, useState } from 'react'
import { Datagrid, TextField, List, ListProps } from 'react-admin'
import AboutUsEdit from './Edit/AboutUs'
import AchievmentEdit from './Edit/Achievment'
import CommunityEdit from './Edit/Community'
import EssayEdit from './Edit/Essay'
import GalleryEdit from './Edit/gallery'
import HomeEdit from './Edit/HomeEdit'
import NewsEdit from './Edit/News'
import { ParentsEdit } from './Edit/ParentsEdit'
import SchoolsEdit from './Edit/SchoolsEdit'
import StudentsEdit from './Edit/StudentsEdit'
import HomeList from './List/HomeList'
import AboutUsList from './List/AboutUsList'
import StudentsList from './List/StudentsList'
import SchoolsList from './List/SchoolsList'
import {ParentsList} from './List/ParentsList'
import CommunityList from './List/CommunityList'
import AchievmentList from './List/AchievmentsList'
import EssayList from './List/EssayList'
import GalleryList from './List/GalleryList'
import NewsList from './List/NewsList'

export const Dashboard = (props: ListProps) => {
    return (
        <List {...props}>
            <Datagrid rowClick="edit">
                <TextField source="id" />
                <TextField source="name" />
            </Datagrid>
        </List>
    )
}
export const DashboardEdit = (props: any) => {
    const [page, setPage] = useState('')
    const userName = localStorage.getItem('username');

    useEffect(() => {
        const urlArr = window.location.href.split('/')
        setPage(urlArr[urlArr.length - 1])
    }, [])
    return (
        userName === 'tbiadmin' ? 
            <>
                {page === 'home' && <HomeEdit {...props} /> }
                {page === 'students' && <StudentsEdit {...props} />}
                {page === 'schools' && <SchoolsEdit {...props} />}
                {page === 'parents' && <ParentsEdit {...props} />}
                {page === 'community' && <CommunityEdit {...props} />}
                {page === 'achievements' && <AchievmentEdit {...props} />}
                {page === 'essay' && <EssayEdit {...props} />}
                {page === 'news' && <NewsEdit {...props} />}
                {page === 'gallery' && <GalleryEdit {...props} />}
                {page === 'About' && <AboutUsEdit {...props} /> }
            </>
        :
        <>
            {page === 'home' && <HomeList {...props} /> }
            {page === 'students' && <StudentsList {...props} />}
            {page === 'schools' && <SchoolsList {...props} />}
            {page === 'parents' && <ParentsList {...props} />}
            {page === 'community' && <CommunityList {...props} />}
            {page === 'achievements' && <AchievmentList {...props} />}
            {page === 'essay' && <EssayList {...props} />}
            {page === 'news' && <NewsList {...props} />} 
            {page === 'gallery' && <GalleryList {...props} />}
            {page === 'About' && <AboutUsList {...props} /> }
    </>
    )
}
