import RichTextInput from 'ra-input-rich-text'
import React from 'react'
import {
    ArrayField,
    Show,
    TabbedShowLayout,
    ListProps,
    Tab,
    ImageField,
    SimpleShowLayout,
    TextField,
    Datagrid,
} from 'react-admin'

const AboutUsList = (props: ListProps) => {
    return (
        <Show {...props} title={'About Us View'} >
            <TabbedShowLayout>
                <Tab label="Section1">
                    <SimpleShowLayout>
                        <TextField source="section1.title" label="Title" />
                        <TextField source="section1.description" label="Description" />
                    </SimpleShowLayout>
                </Tab>
                <Tab label="Section2">
                    <SimpleShowLayout>
                        <ArrayField source="section2.quotes" label="Acheivements" >
                            <Datagrid>
                                <ImageField source='image.src' label={''} />
                                <TextField source="content" label="Content" />
                                <TextField source="subheading" label="Subheading" />
                            </Datagrid>
                        </ArrayField>
                    </SimpleShowLayout>
                </Tab>
            </TabbedShowLayout>
        </Show>
    )
}

export default AboutUsList
