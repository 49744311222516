import RichTextInput from 'ra-input-rich-text'
import React from 'react'
import {
    ArrayInput,
    Edit,
    EditProps,
    FormTab,
    ImageField,
    ImageInput,
    SimpleFormIterator,
    TabbedForm,
    TextInput,
} from 'react-admin'

const AboutUsEdit = (props: EditProps) => {
    return (
        <Edit {...props}>
            <TabbedForm>
            <FormTab label="Section1">
                    <RichTextInput source="section1.title" label="Title" />
                   
                    <RichTextInput source="section1.description" label="Description" />
                </FormTab>
                <FormTab label="Section2">
                    <ArrayInput
                        source="section2.quotes"
                        label="Acheivements"
                    >
                        <SimpleFormIterator>
                            <ImageInput
                                {...props}
                                accept="image/*"
                                source="image"
                                multiple={false}
                            >
                                <ImageField source="src" title="title" />
                            </ImageInput>
                            <TextInput source="content" label="Content" />
                            <TextInput source="subheading" label="Subheading" />
                        </SimpleFormIterator>
                    </ArrayInput>
                </FormTab>
            </TabbedForm>
        </Edit>
    )
}

export default AboutUsEdit
