import { AuthProvider } from 'react-admin'
const authProvider = {
    // called when the user attempts to log in
    login: ({ username, password } : {username: string; password: string}) => {
      localStorage.setItem("username", username);
      // accept all username/password combinations
      return new Promise((resolve, reject) => {
        if (username === 'tbiadmin' && password === 'tbiIndia@23') {
            resolve({
                token: 'fake-jwt-token',
            })
        } 
        else if(username === 'tbiuser' && password === 'tbiUser@23') {
          resolve({ 
            token: 'fake-jwt-token2'})
        } else {
            reject('Wrong credentials')
        }
        })
    },
    // called when the user clicks on the logout button
    logout: () => {
      localStorage.removeItem("username");
      return Promise.resolve();
    },
    // called when the API returns an error
    checkError: ({ status } : { status: number}) => {
      if (status === 401 || status === 403) {
        localStorage.removeItem("username");
        return Promise.reject();
      }
      return Promise.resolve();
    },
    // called when the user navigates to a new location, to check for authentication
    checkAuth: () => {
      return localStorage.getItem("username")
        ? Promise.resolve()
        : Promise.reject();
    },
    // called when the user navigates to a new location, to check for permissions / roles
    getPermissions: () => Promise.resolve(),
  };
  
  export default authProvider
